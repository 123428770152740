:root {
  --base-white: #fff;
  --black: #000000;
  --grey: #F2F2F2;
  --grey-1: #374151;
  --grey-2: #E7E8E9;
  --gray: #5A7284;
  --gray-1: #D9D9D9;
  --gray-2: #D1D5DB;
  --lightGray: #DDDDDD;
  --text: #6B7280;
  --red: #C32126;
  --yellow: #FEB017;
  --green : #34A853;
  --blackOpacity: #242424;
}
