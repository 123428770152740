// layout
.ant-layout {
    background: transparent;
    .ant-layout-sider {
        height: 100vh;
        left: 0;
        background: #f2f2f2 !important;
        .ant-menu-dark {
            background: transparent;
        }
    }
    .ant-menu {
        .ant-menu-item {
            .ant-menu-item-icon {
                svg path {
                    fill: #000;
                }
            }
            .ant-menu-title-content {
                color: #000;
            }
            &.ant-menu-item-selected {
                .ant-menu-item-icon {
                    svg path {
                        fill: #fff;
                    }
                }
                .ant-menu-title-content {
                    color: #fff;
                }
            }
        }
    }
}

// orders
.ant-tabs-nav {
    margin-bottom: 30px !important;
    .ant-tabs-nav-wrap {
        background: var(--grey);
        border-radius: 6px;
        padding: 4px;
        .ant-tabs-tab {
            padding: 10px 20px;
            color: var(--black);
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            opacity: 0.5;
            margin: 0;
            &.ant-tabs-tab-active {
                background: var(--base-white);
                border-radius: 4px;
                opacity: 1;
                border: none !important;
                .ant-tabs-tab-btn {
                    color: var(--black);
                }
                .ant-tabs-ink-bar {
                    background-color: transparent;
                }
            }
        }
        .ant-tabs-ink-bar {
            display: none;
        }
    }
}

.ant-table {
    .ant-table-content {
        border: 1px solid var(--gray-1);
        border-radius: 8px;

        .ant-table-thead {
            .ant-table-cell {
                background-color: transparent;
                &::before {
                    display: none;
                }
            }
        }
        .ant-table-tbody {
            .ant-table-cell {
                background: transparent !important;
            }
        }
    }
}

// profile
.ant-radio-group {
    .ant-radio-wrapper {
        font-size: 16px;
        line-height: 28px;
        .ant-radio {
            width: 20px;
            height: 20px;
            border: 1px solid var(--lightGray);
            background: var(--base-white);
            .ant-radio-inner {
                display: none;
            }
        }
        span {
            color: var(--gray);
        }
    }
    .ant-radio-wrapper-checked {
        background-color: rgba(195, 33, 38, 0.05);
        border-color: rgba(195, 33, 38, 0.25) !important;
        .ant-radio {
            background: var(--red);
        }
        span {
            color: var(--red);
        }
    }
}
.ant-input {
    padding: 9px 13px;
}
.ant-select {
    height: auto;
    .ant-select-selector {
        padding: 5px 11px !important;
    }
}
.ant-picker {
    .ant-picker-input {
        input {
            padding: 5px 0;
        }
    }
}
.ant-upload-wrapper.profile-upload {
    display: grid;
    width: 100% !important;
}
.ant-input-affix-wrapper {
    padding: 9px 13px;
}

// ant-modal
.ant-modal {
    z-index: 9999;
    .ant-modal-content {
        border-radius: 20px;
    }
}

// truck/vehicle type
.ant-steps {
    margin-bottom: 60px;
    .ant-steps-item-process {
        position: relative;
        padding-top: 30px !important;
        &::before {
            content: "Step 1";
            position: absolute;
            width: 50px;
            height: 20px;
            color: #000;
            font-size: 16px;
            font-weight: 700;
            left: 0;
            top: -0px;
        }
    }
    .ant-steps-item-wait{
        position: relative;
        padding-top: 30px !important;
        &::before {
            content: "Step 2";
            position: absolute;
            width: 50px;
            height: 20px;
            color: #000;
            font-size: 16px;
            font-weight: 700;
            right: 0;
            top: -0px;
        }
    }
    .ant-steps-item {
        .ant-steps-item-container {
            display: flex;
            align-items: center;
        }
        .ant-steps-item-icon {
            width: 22px;
            height: 22px;
            background-color: var(--base-white) !important;
            border: 6px solid var(--lightGray);
            span {
                display: none;
            }
        }
        &.ant-steps-item-active {
            .ant-steps-item-icon {
                border-color: var(--red);
            }
        }
    }
    .ant-steps-item-active {
        .ant-steps-item-content {
            h2 span {
                color: var(--red);
            }
        }
    }
    .ant-steps-item-finish {
        .ant-steps-item-content {
            h2 {
                color: var(--lightGray);
            }
        }
    }
    .ant-steps-item-title {
        font-size: 20px;
        font-weight: 700;
        &::after {
            height: 3px;
            background: var(--lightGray);
        }
    }
}

.ant-input-group-wrapper {
    .ant-input-group-addon {
        padding: 0;
        background-color: transparent;
        .ant-btn {
            width: 100%;
            height: 100%;
            border: none;
            background-color: transparent;
            box-shadow: none;
            outline: none;
            div {
                display: none;
            }
            &:hover {
                background-color: transparent;
                box-shadow: none;
            }
        }
        .ant-click-animating-node {
            display: none;
        }
    }
    .ant-input {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        color: var(--black);
    }
}

// common
.ant-btn {
    height: auto;
    div {
        display: none;
    }
}

// header
.ant-dropdown-menu {
    .ant-dropdown-menu-item {
        .headerDropDownMenu {
            span {
                margin-left: 8px;
                font-weight: 500;
            }
        }
        .ant-dropdown-menu-title-content {
            .ordernotificationItem {
                display: flex;
                flex-direction: column;
                width: 380px;
                border-radius: 8px;
                padding: 10px;
                background: #fafafa;
                margin-bottom: 15px;
                &.unseennotification {
                    background-color: #e7e8e9;
                }
                &:last-of-type {
                    margin-bottom: 0;
                }
                h4 {
                    margin-bottom: 2px;
                    font-weight: 700;
                    font-size: 16px;
                }
                p {
                    margin-bottom: 10px;
                }
                .viewAllNotification {
                    background-color: rgba(195, 33, 38, 0.25);
                    border: 1px solid rgba(195, 33, 38, 0.25);
                    color: #c32126;
                    border-radius: 50px;
                    padding: 6px;
                    text-align: center;
                    width: 150px;
                    margin: 15px 0 0 auto;
                }
            }
        }
    }
}

@media only screen and (max-width: 1199px) {
    .ant-steps {
        margin-bottom: 40px;
    }

    .ant-radio-group {
        display: flex !important;
        flex-wrap: wrap !important;
    }
    .ant-form-item {
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 576px) {
    // profile
    .ant-radio-group {
        .ant-radio-wrapper {
            margin-bottom: 0px;
        }
    }

    // order
    .ant-tabs-nav {
        &::before {
            display: none;
        }
        .ant-tabs-nav-wrap {
            .ant-tabs-nav-list {
                width: 100%;
                flex-wrap: wrap;
                .ant-tabs-tab {
                    width: 100%;
                    justify-content: center;
                }
            }
        }
    }

    // truck/vehicle type
    .ant-steps {
        height: auto;
        margin-bottom: 25px;
        .ant-steps-item {
            .ant-steps-item-tail {
                left: 10px !important;
                &::after {
                    width: 3px !important;
                }
            }
            .ant-steps-item-content {
                min-height: auto !important;
            }
        }
    }
}
